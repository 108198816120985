<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="open">
        <div v-if="open">
          <div class="flix-form-group" v-for="(data, index) in filters" :key="index">
            <ul class="flix-nav flix-nav-pills flix-html-ul">
              <li v-for="(description, f) in data" :key="f" class="flix-html-li" :class="{'flix-active': filter === f}"><a href="#" @click.prevent="openFilter(f)" class="flix-html-a"><flixIcon id="filter" v-if="f !== 'all'"/> <span v-html="description" /></a></li>
            </ul>
          </div>
        </div>
        <div v-else>
          <div class="flix-form-group">
            <ul class="flix-nav flix-nav-pills flix-html-ul">
              <li class="flix-html-li flix-active"><a href="#" @click.prevent="open = true" class="flix-html-a"><flixIcon id="filter" v-if="filter !== 'all'"/> <span v-html="getDescription()" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    setFilter: Function,
    filter: [String, Date]
  },
  data () {
    return {
      open: false,
      filters: [
        { today: this.$t('message.today') },
        {
          tomorrow: this.$t('message.tomorrow'),
          '+2day': '+ 2 ' + this.$t('message.days'),
          '+3day': '+ 3 ' + this.$t('message.days'),
          '+1week': '+ 1 ' + this.$tc('message.weeks', 1),
          '+1month': '+ 1 ' + this.$tc('message.month', 1)
        },
        {
          yesterday: this.$t('message.yesterday'),
          '-2day': '- 2 ' + this.$t('message.days'),
          '-3day': '- 3 ' + this.$t('message.days'),
          '-1week': '- 1 ' + this.$tc('message.weeks', 1),
          '-1month': '- 1 ' + this.$tc('message.month', 1)
        },
        { all: '&infin; ' + this.$tc('message.appointments', 2) }
      ]
    }
  },
  methods: {
    getDescription () {
      var r = ''
      var filter = this.filter
      this.filters.forEach(function (f1) {
        Object.keys(f1).forEach(function (k) {
          if (k === filter) {
            r = f1[k]
          }
        })
      })
      return r
    },
    openFilter (filter) {
      if (this.filter === filter) {
        this.open = !this.open
        return false
      }
      this.setFilter(filter)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-html-li
    background-color: lightgrey
</style>
